<template>
  <div>
    <CRow class="justify-content-between mb-3">
      <CCol sm="auto"
        ><div class="ui-type-display-lg">
          {{ $t("user.profile.families.title") }}
        </div>
      </CCol>
      <CCol sm="auto">
        <CButton color="primary" @click="onAddEmail()">
          {{ $t('user.profile.families.add_btn') }}
        </CButton>
      </CCol>
    </CRow>

    <CCard>
      <CCardBody>
        <CAlert v-if="error.hasGeneralErrors()" color="danger" class="mb-3">
          {{ error.general().join(" ") }}
        </CAlert>

        <ejs-grid
          :dataSource="dm"
          :allowPaging="true"
          :allowSorting="true"
          :query="query"
          ref="grid"
        >
          <e-columns>
            <e-column :headerText="$t('models.family.name')" :template="nameTemplate"></e-column>
            <e-column
              :headerText="$t('models.family.f_type')"
              field="f_type"
              :template="typeTemplate"
            ></e-column>
            <e-column :headerText="$t('models.family.role')" :template="roleTemplate"></e-column>
            <e-column
              :headerText="$t('models.family.ts_created')"
              field="ts_created"
              :template="createdTemplate"
            ></e-column>
            <e-column headerText="" :template="actionsTemplate"></e-column>
          </e-columns>
        </ejs-grid>
      </CCardBody>
    </CCard>

    <CModal :show="modal.show" :closeOnBackdrop="false" :centered="true">
      <template #header>
        <h5 class="modal-title">{{ $t('user.profile.families.modal.title') }}</h5>
      </template>

      <CAlert v-if="modal.error.hasGeneralErrors()" color="danger" class="mb-3">
        {{ modal.error.general().join(" ") }}
      </CAlert>

      <form action="" @submit.prevent="addBill">
        <CInput
          v-model="modal.form.name"
          :label="$t('models.family.name')"
          type="text"
          :isValid="modal.error.isValidField('name')"
          :invalidFeedback="modal.error.fieldError('name')"
          required
        />

        <CSelect
          :label="$t('models.family.f_type')"
          :options="familyTypeOptions"
          :value.sync="modal.form.type"
          :isValid="modal.error.isValidField('f_type')"
          :invalidFeedback="modal.error.fieldError('f_type')"
          custom
        />
      </form>

      <template #footer>
        <CButton color="primary" variant="outline" @click.prevent="closeModal"
          >{{ $t('common.editor.cancel')}}</CButton
        >
        <vue-ladda
          :loading="modal.loading"
          data-style="zoom-in"
          button-class="btn btn-primary px-4"
          @click.prevent="addBill"
          >{{ $t('common.editor.confirm')}}</vue-ladda
        >
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import { Page, Sort } from "@syncfusion/ej2-vue-grids";
import { GetDataManagerNew } from "../../../ds";
import { Query } from "@syncfusion/ej2-data";
// import TableDate from "./TableDate";
// import TableType from "./TableType";
// import TableRole from "./TableRole";
// import TableActions from "./TableActions";
import EventBus from "../../../helpers/EventBus";
import { getOptionLabel, isValidDate, formatDate } from "../../../helpers/common";
import errorResponse from "../../../helpers/error";
import { getFamilyTypeOptions, getRoleTypeOptions } from "../../../helpers/options";
import get from "lodash/get";

export default Vue.extend({
  name: "Family",

  data() {
    const dm = GetDataManagerNew("auth_family");
    // const familyTypeOptions = getFamilyTypeOptions(this);
    const query = new Query();//.sortBy("name", "ascending");

    return {
      dm: dm,
      query: query,
      error: errorResponse(),
      modal: {
        show: false,
        loading: false,
        error: errorResponse(),
        // familyTypeOptions: getFamilyTypeOptions(this),
        form: {
          name: "",
          type: getFamilyTypeOptions(this)[0].value,
        },
      },

      nameTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                <CIcon name="cil-star" v-if="favorite()"/> {{ value() }}
              </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            methods: {
              value() {
                return this.data.name;
              },
              favorite(){
                return this.data.is_favorite
              }
            },
          }),
        };
      },
      typeTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                {{ value() }}
              </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            methods: {
              value() {
                return getOptionLabel(this.data.f_type, getFamilyTypeOptions(this));
              },
            },
          }),
        };
      },

      createdTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                {{ formattedDate() }}
              </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            methods: {
              formattedDate() {
                const date = new Date(this.data.ts_created);
                return isValidDate(date) ? formatDate(date, "dd-MM-yyyy · HH:mm") : "--";
              },
            },
          }),
        };
      },

      roleTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                {{ getRoleDisplay() }}
              </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            methods: {
              getRoleDisplay() {
                const r_type = get(this.data, "role.r_type", null);
                if (r_type){
                  return getOptionLabel(r_type, getRoleTypeOptions(this));
                }
                return "";
              },
            },
          }),
        };
      },

      actionsTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div class="d-flex justify-content-end" v-if="!data.is_favorite">
                <dropdown-menu :items="actions" :select="onActionSelect">
                  <font-awesome-icon icon="ellipsis-h"
                /></dropdown-menu>
              </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            computed: {
              actions() {
                const isActive =
                  this.data.id ===
                  get(this.$store, "state.connections.current.family.id");
                const FAMILY_ACTIONS = [
                  { id: "DELETE", text: this.$t('user.profile.families.delete') },
                  { id: "FAVORITE", text: this.$t('user.profile.families.favorite') }
                  ];
                return FAMILY_ACTIONS.filter((a) => {
                  return (a.id === "DELETE" && isActive)||(a.id === "FAVORITE" && this.data.is_favorite) ? false : true;
                });
              },
            },
            methods: {
              onActionSelect: function (args) {
                const { id } = args.item;

                if (id === "DELETE") {
                  if (
                    confirm(
                      this.$t("user.profile.families.delete_confirm", [this.data.name])
                    ) == true
                  ) {
                    EventBus.$emit("family:remove", this.data);
                  }
                }else if(id === "FAVORITE") {
                    EventBus.$emit("family:favorite", this.data);
                }
              },
            },
          }),
        };
      },

      // actionsTemplate: () => {
      //   return { template: TableActions };
      // },
    };
  },
  computed: {
    familyTypeOptions() {
      return getFamilyTypeOptions(this);
    },
  },
  provide: {
    grid: [Page, Sort],
  },

  mounted() {
    EventBus.$on("family:remove", this.onRemove);
    EventBus.$on("family:favorite", this.onFavorite);
  },

  beforeDestroy() {
    EventBus.$off("family:remove", this.onRemove);
    EventBus.$off("family:favorite", this.onFavorite);
  },

  methods: {
    onAddEmail() {
      this.modal.error.reset();
      this.modal.form.name = "";
      this.modal.form.type = this.familyTypeOptions[0].value;
      this.modal.show = true;
    },

    closeModal() {
      this.modal.show = false;
    },

    addBill() {
      const requestParams = {
        name: this.modal.form.name,
        f_type: this.modal.form.type,
      };
      this.modal.error.reset();
      this.dm
        .insert(requestParams)
        .then((response) => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: this.$t('user.profile.families.add_success'),
            color: "success",
            autohide: true,
          });
          this.modal.show = false;
          this.$refs.grid.refresh();
          this.$store.dispatch("connections/fetchAll");
        })
        .catch((response) => {
          const body = JSON.parse(response[0].error.response);
          this.modal.error.set(body.errors);
        });
    },

    removeEmail(id) {
      this.error.reset();
      this.dm
        .remove("", id)
        .then((response) => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: this.$t('user.profile.families.delete_success'),
            color: "success",
            autohide: true,
          });
          this.$refs.grid.refresh();
          this.$store.dispatch("connections/fetchAll");
        })
        .catch((response) => {
          const body = JSON.parse(response[0].error.response);
          this.error.set(body.errors);
        });
    },

    onRemove(item) {
      this.removeEmail(item.id);
    },
    onFavorite(item) {
      this.favoriteFamily(item.id, item.luc_id);
    },

    favoriteFamily(id, luc_id) {
      this.error.reset();
      console.log("set favorite family", id, luc_id)
      const dm = GetDataManagerNew("auth_family_connection", [id])
      const params = {
        id:luc_id,
        favorite: true
      };
      dm
        .update("", params)
        .then((response) => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: this.$t('user.profile.families.favorite_success'),
            color: "success",
            autohide: true,
          });
          this.$refs.grid.refresh();
          this.$store.dispatch("connections/fetchAll");
        })
        .catch((response) => {
          const body = JSON.parse(response[0].error.response);
          this.error.set(body.errors);
        });
    },
  },
});
</script>
